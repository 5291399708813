<template>
    <div>
        <div class="container-pa16">
            <div @scroll="$_onScroll" class="scroll-container container-pa16">
                <div
                    v-for="item in records"
                    :key="item.key"
                    @click="goDetail(item)"
                >
                    <div class="record">
                        <div class="flex-container record-header flex-center">
                            <div class="flex-container flex-column">
                                <div class="flex-container flex-center">
                                    <img
                                        class="record-logo"
                                        :src="
                                            require('@/assets/imgs/svg/0603-b.svg')
                                        "
                                    />
                                    <h3 class="title">
                                        {{ item.name }}
                                    </h3>
                                </div>

                                <span style="margin: 0px 0px 10px 10px"
                                    >費用：${{ item.price }}</span
                                >
                            </div>
                        </div>
                        <div class="body">
                            <div
                                class="flex-container space-between flex-center"
                            >
                                <span class="text-875">
                                    租借日期：{{ item.order_date }}
                                </span>
                                <img class="payment" :src="item.payment" />
                            </div>
                            <div
                                class="flex-container space-between flex-center"
                            >
                                <span class="text-875">
                                    歸還日期：{{ item.backTime }}
                                </span>
                            </div>
                            <div
                                class="flex-container space-between flex-center"
                            >
                                <span class="text-875">
                                    歸還地點：{{ item.backAddress }}
                                </span>
                                <div class="pay-style">
                                    <span v-if="item.status" class="text-75">
                                        {{ item.status }}
                                    </span>

                                    <div
                                        v-if="item.status === '待支付'"
                                        class="title border"
                                        @click="linePay"
                                    >
                                        支付
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        </div>
    </div>
</template>
<script>
import { handleErr } from "@/helpers";
import { Dialog } from "vant";
export default {
    beforeCreate() {
        // const token = localStorage.getItem("token");
        const token = window.$cookies.get("token");

        if (token) {
            this.$axios.defaults.headers.common["token"] = token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }
        // const setRecords = async () => {
        //     try {
        //         const res = await this.$axios.get("/customer/order/list");
        //         // console.log("record list", res);
        //         if (res.data.success) {
        //             this.records = [].concat(
        //                 res.data.result.records.map((record) => ({
        //                     ...record,
        //                     id: record.orderId,
        //                     name: record.storeName,
        //                     price: record.money,
        //                     device_id: record.deviceSn,
        //                     order_id: record.sn,
        //                     order_date: record.createTime,
        //                     payment: this.getPayChannelIcon(record.payChannel),
        //                     status: this.getOrderStatus(record.status),
        //                 }))
        //             );
        //         } else {
        //             throw res;
        //         }
        //     } catch (error) {
        //         handleErr({ ...error, app: this.$parent });
        //     }
        // };
        // setRecords();
    },
    data() {
        return {
            isLoading: true,
            records: [
                // {
                //     name: "店名（分店）",
                //     price: "2.00",
                //     device_id: "",
                //     order_id: "",
                //     order_date: "2018-9-11 19:42:05",
                //     payment: "0603-c.svg",
                //     status: "已完成",
                // },
            ],
            appUrl: "",
            currentPage: 1,
        };
    },
    mounted() {
        this.setRecords();
    },
    methods: {
        getOrderStatus(status) {
            switch (status) {
                case "DONE":
                    return "已完成";
                case "USING":
                    return "使用中";
                case "WAITING_PAID":
                    return "待支付";
                case "FAIL":
                    return "失败";
                case "REFUNDED":
                    return "退款";
                case "REFUNDFAILED":
                    return "退款失败";
                case "REFUNDDING":
                    return "退款中";
                default:
                    return "";
            }
        },
        getPayChannelIcon(payChannel) {
            switch (payChannel) {
                // 信用卡支付
                case "direct":
                    return require("@/assets/imgs/svg/0603-e.svg");
                // 苹果支付
                case "applePay":
                    return require("@/assets/imgs/svg/0603-c.svg");
                // 谷歌支付 FIXME: lack icon
                case "googlePay":
                    return require("@/assets/imgs/svg/0603-e.svg");
                // 街口支付
                case "jkos":
                    return require("@/assets/imgs/svg/0603-d.svg");
                // Line支付
                case "linePay":
                    return require("@/assets/imgs/png/line_pay.jpg");
            }
        },
        async byLinePay() {
            try {
                const res = await this.$axios.post("/linePay/payment3", {});
                if (res.data.success) {
                    // this.appUrl = res.data.result.appUrl;
                    this.appUrl = res.data.result.webUrl;
                    window.location.href = this.appUrl;
                } else {
                    throw res;
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },

        linePay() {
            Dialog.confirm({
                title: "確認LINE PAY支付",
                message: "",
                confirmButtonText: "確認",
            })
                .then(() => {
                    // on confirm
                    console.log("confirm");
                    this.byLinePay();
                })
                .catch(() => {
                    // on cancel
                    console.log("cancel");
                });
        },
        goDetail(item) {
            this.$router.push(`/OrderDetail/${item.sn}`);
        },
        $_onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
            if (this.isLoading) return;
            if (scrollTop + clientHeight >= scrollHeight) {
                this.currentPage += 1;
                this.setRecords();
            }
        },

        async setRecords() {
            this.isLoading = true;
            try {
                const res = await this.$axios.get(
                    "/customer/order/list?page=" + this.currentPage
                );
                const new_records = [].concat(
                    res.data.result.records.map((record) => ({
                        ...record,
                        id: record.orderId,
                        name: record.storeName,
                        price: record.money,
                        device_id: record.deviceSn,
                        order_id: record.sn,
                        order_date: record.createTime,
                        payment: this.getPayChannelIcon(record.payChannel),
                        status: this.getOrderStatus(record.status),
                    }))
                );
                if (this.currentPage === 1) {
                    this.records = new_records;
                } else {
                    this.records.push(...new_records);
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
            this.isLoading = false;
        },
    },
};
</script>
<style scoped>
.border {
    font-size: 12px;
    font-weight: 500;
    background: #2c394e;
    color: #ffffff;
    padding: 2px 4px;
    border-radius: 5px;
    margin-top: 5px;
}
.pay-style {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
